<template>
  <layout :title="$route.name">
    <div class="expande-horizontal wrap">
      <InvestmentItem
        v-for="investment in getInvestments"
        :key="investment._id"
        :purchase="purchase"
        :investment="investment"
      />
    </div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InvestmentItem from "./components/InvestmentItem";
export default {
  components: {
    InvestmentItem
  },
  computed: {
    ...mapGetters(["getInvestments"])
  },
  methods: {
    ...mapActions(["listInvestments"])
  },
  created() {
    this.listInvestments();
  }
};
</script>
